







































































































































































































































































































































































































































@import './style.scss';
.modal-cadastro-form-col-imput .v-text-field--outlined.error--text::v-deep {
  .v-input__slot {
    margin-bottom: 20px !important;
  }
  .v-text-field__details {
    bottom: 5px;
  }
}

.modal-cadastro2 {
  .br-btn {
    width: 100%;
    max-width: 250px;
    margin: 0 !important;

    &-cancelar {
      margin-bottom: 10px !important;
    }
  }

  > .v-card .modal-cadastro-footer {
    flex-direction: column;
  }
}

.fab-remove-item {
  width: 20px !important;
  height: 20px !important;
  min-height: 20px !important;
  min-width: 20px !important;
}

.input-phone::v-deep {
  .v-input__prepend-outer {
    margin-top: 0 !important;
    margin-right: 0 !important;
  }

  .v-btn {
    height: 40px !important;
    border-radius: 4px 0 0 4px;
    background: #d3d3d3 !important;
    color: #000 !important;
  }
}
