














































































































































































































































































































































































@import '../../../../../assets/styles/components/formularios';
@import '../../../../../assets/styles/components/modal-cadastro';
.br-btn-entity-products {
  margin-top: 10px;
  min-width: auto !important;
  font-size: 0.8em !important;
}
