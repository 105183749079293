
































































































































































































































































@import './style.scss';
.modal-cadastro-form-col-imput .v-text-field--outlined.error--text::v-deep {
  .v-input__slot {
    margin-bottom: 20px !important;
  }
  .v-text-field__details {
    bottom: 5px;
  }
}

.modal-cadastro2 {
  .br-btn {
    width: 100%;
    max-width: 250px;
    margin: 0 !important;

    &-cancelar {
      margin-bottom: 10px !important;
    }
  }

  > .v-card .modal-cadastro-footer {
    flex-direction: column;
  }
}
